import React, { useEffect, useMemo } from 'react';
import DataTable from '../../common/Dashboard';
import { columns } from './enumerations';
import { useBusiness } from '../../../hooks/useBusiness';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import Box from '@mui/material/Box';
import { useThrottle } from '@react-hook/throttle';
import { debounce } from 'lodash';

interface Business {
  id: String;
  name: String;
  address?: String;
  description?: String;
  userId?: String;
  postalCode?: String;
  phone?: String;
  countryId?: String;
  email?: String;
  createdAt?: String;
  updatedAt?: String;
  logo?: String;
}

const AdminDashboard: React.FC = () => {
  const [search, setSearch] = React.useState('');
  const [field, setField] = React.useState('name');
  const [pageSize, setPageSize] = React.useState(20);
  const [page, setPage] = React.useState(0);
  const { data, isFetching, refetch } = useBusiness(undefined, page, pageSize, search);

  useEffect(() => {
    refetch();
  }, [page]);

  useEffect(() => {
    if(!page){
      refetch();
    }else{
      setPage(0)
    }
  }, [search, pageSize]);

  const handleChange = useMemo(
    () =>
      debounce((e: any) => setSearch(e.target.value), 1000)
    , [search]);

  useEffect(() => {
    return () => handleChange.cancel();
  }, []);

  return (
    <React.Fragment>
      {/* <FormControl sx={{ m: 1, width: '20ch' }} variant="outlined">
        <InputLabel id="demo-simple-select-label">Field</InputLabel>
        <Select
          labelId="label-select-field-search"
          id="select-field-search"
          value={field}
          label="Field"
          onChange={handleFieldChange}
        >
          <MenuItem value={'id'}>Id</MenuItem>
          <MenuItem value={'name'}>Name</MenuItem>
          <MenuItem value={'address'}>Address</MenuItem>
          <MenuItem value={'description'}>Description</MenuItem>
          <MenuItem value={'userId'}>User Id</MenuItem>
          <MenuItem value={'postalCode'}>Postal Code</MenuItem>
          <MenuItem value={'phone'}>Phone</MenuItem>
          <MenuItem value={'countryId'}>Country Id</MenuItem>
          <MenuItem value={'email'}>Email</MenuItem>
          <MenuItem value={'createdAt'}>Created At</MenuItem>
          <MenuItem value={'updatedAt'}>Updated At</MenuItem>
          <MenuItem value={'logo'}>Logo</MenuItem>
        </Select>
      </FormControl> */}
      <FormControl sx={{ m: 1, width: '70ch' }} variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">Search</InputLabel>
        <OutlinedInput
          id="outlined-adornment-search"
          type={'search'}
          defaultValue={search}
          onChange={handleChange}
          label="Password"
        />
      </FormControl>
      {/* {!isFetching ? ( */}
      <DataTable
        pageSize={pageSize}
        page={page}
        columns={columns}
        count={data?.response?.count || 0}
        rows={data?.response?.businesses || []}
        loading={isFetching}
        setPage={setPage}
        setPageSize={setPageSize}
      />
      {/* ) : (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )} */}
    </React.Fragment>
  );
};

export default AdminDashboard;
