import { Autocomplete, Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { Container } from '@mui/system';
import { useEffect, useState, useMemo } from 'react'
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { apiInstance } from '../../services/axios';
import { debounce } from 'lodash';
import { title } from 'process';

interface imageType {
    logoFormData: null | any;
    url: string;
}

interface TeaserData {
    b_id: string,
    country_code: string,
    created_at: string,
    description: string,
    id: string,
    img_url: string,
    language_code: string,
    offer_type: string,
    sort_order: number,
    status: string,
    title: string,
    updated_at: string,
}

const AddTeaser = () => {
    const params = useParams();
    const navigate = useNavigate()
    const [image, setImage] = useState<imageType>({ logoFormData: null, url: "" });
    const [imgName, setImgName] = useState(undefined);
    const [type, setType] = useState('');
    const [status, setStatus] = useState('');
    const [teaserData, setTeaserData] = useState<TeaserData>();
    const [businessData, setBusinessData] = useState<any[]>([]);
    const [search, setSearch] = useState('');
    const [businessId, setBusinessId] = useState('');
    const [businessName, setBusinessName] = useState('');

    const productRewardSchema = yup.object({
        title: yup.string()
            .required('Title is required.'),
        sort_order: yup.string()
            .required('Sort order is required.'),
        offer_type: yup.string()
            .required('Offer type is required.'),
        status: yup.string()
            .required('Status is required.'),
        description: yup.string()
            .required('Description is required.'),
        b_id: yup.string()
            .required('Business Id is required.'),
    });

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    }: any = useForm({
        resolver: yupResolver(productRewardSchema),
    });

    const getBusinessData = () => {
        let url = `/business-service/business?page=0&limit=50`
        if (search) {
            url = url + `&search=${search}`
        }
        apiInstance.get(url).then((res) => {
            setBusinessData(res.data.response.businesses)
        })
    }

    useEffect(() => {
        console.log("🚀 ~ file: AddTeaser.tsx:86 ~ AddTeaser ~ search:😀😀", search)
        getBusinessData()
        if (search) {
            setBusinessData([])
        }
    }, [search])

    const handleTeaserData = async (payload: any) => {
        let logoURL = undefined;
        let uploadRes;
        if (image.logoFormData) {
            uploadRes = await apiInstance.post(`file-service/file/upload`, image?.logoFormData,);
            const { data: { response: {
                url
            } } }: any = uploadRes;
            logoURL = url
            setImage(url)
        }
        const returnData = {
            title: payload?.title && payload?.title,
            sort_order: payload?.sort_order && payload?.sort_order,
            offer_type: payload?.offer_type && payload?.offer_type,
            select_status: payload?.select_status && payload?.select_status,
            description: payload?.description && payload?.description,
            img_url: image.url ?? logoURL,
            b_id: businessId
        }

        if (params.id) {
            apiInstance.patch(`/teaser-service/offer/${params.id}`, returnData).then((res) => {
                console.log('first🔥🔥', res)
                navigate('/teaser')
            }).catch((err) => {
                console.log('err', err)
            })
        } else {
            apiInstance.post('/teaser-service/offer', returnData).then((res) => {
                console.log('postRES', res)
                navigate('/teaser')
            }).catch((err) => {
                console.log('err', err)
            })
        }
    }

    useEffect(() => {
        if (params.id) {
            apiInstance.get(`/teaser-service/offer/${params.id}`).then((res) => {
                setTeaserData(res.data.response)
                Object.keys(res.data.response).map((ele: any) => {
                    setValue(ele, res.data.response[ele])
                })
                setBusinessId(res.data.response.b_id)
                setType(res.data.response.offer_type)
                setStatus(res.data.response.status)
                setImage({ ...image, url: res.data.response.img_url })
                apiInstance.get(`/business-service/business/${res.data.response.b_id}`).then((res) => {
                    setBusinessName(res.data.response.name)
                })
            })
        }
    }, [params.id])

    const postUploadfile = (file: any) => {
        setImgName(file.target.files[0].name)
        const form_data: any = new FormData()
        form_data.append('file', file?.target.files[0])
        form_data.append('group', 'teaser')
        form_data.append('subgroup', '')
        form_data.append('filename', new Date().getTime())
        form_data.append('url', URL.createObjectURL(file?.target.files[0]))
        setImage({ logoFormData: form_data, url: URL.createObjectURL(file?.target.files[0]) });
    }

    const handleChange = (e: any, val: any) => {
        setBusinessId(val.id)
        setValue('b_id', val.id);
    }

    const handleSearch = useMemo(
        () =>
            debounce((e: any) => {
                setSearch(e.target.value.trim())
            }, 1000)
        , [search]);

    useEffect(() => {
        return () => handleSearch.cancel();
    }, []);

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '24px' }}>
                <h2 style={{ margin: '0px' }} className="title">{params.id ? 'Edit' : 'Add'} Teaser</h2>
                <div style={{ display: 'flex', gap: '20px' }}>
                    <Button variant="contained" sx={{}}
                        onClick={handleSubmit((data: any) => {
                            handleTeaserData(data)
                        })}
                    >
                        {params.id ? 'Edit' : 'Add'}
                    </Button>
                    <Button variant="outlined" sx={{}}
                        onClick={() => navigate('/teaser')}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
            <Container maxWidth="xl">
                <form>
                    {teaserData && <p>Business :- <span style={{ fontWeight: '600' }}>{businessName}</span></p>}
                    <Grid className='teaser_main' container spacing={2}>
                        {
                            !teaserData &&
                            <Grid item md={4}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="country-select-demo"
                                        options={businessData}
                                        filterOptions={x => {
                                            console.log("x", x)
                                            return x
                                        }}
                                        onChange={(e, val) => handleChange(e, val)}
                                        getOptionLabel={(option: any) => option.name}
                                        renderOption={(props, option) => (
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                {option.name}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                onChange={handleSearch}
                                                label="Select Business Id"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password',
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                                {errors?.b_id && <label style={{ color: "red" }}>{errors?.b_id?.message}</label>}
                            </Grid>
                        }
                        <Grid item md={4}>
                            <FormControl fullWidth >
                                <TextField
                                    label='Add title'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    type="text"
                                    {...register('title')}
                                    defaultValue={getValues('title')}
                                    onChange={(e) => {
                                        setValue('title', e.target.value)
                                    }}
                                    aria-label="default input example"
                                    placeholder='Add title'
                                />
                                {errors?.title && <label style={{ color: "red" }}>{errors?.title?.message}</label>}
                            </FormControl>
                        </Grid>
                        <Grid item md={4}>
                            <FormControl fullWidth>
                                <TextField
                                    label='Sort order'
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    placeholder="Sort Order"
                                    aria-label="default input example"
                                    defaultValue={teaserData?.sort_order}
                                    {...register('sort_order')}
                                />
                            </FormControl>
                            {errors?.sort_order && <label style={{ color: "red" }}>{errors?.sort_order?.message}</label>}
                        </Grid>
                        <Grid item md={4}>
                            <FormControl fullWidth >
                                <InputLabel id="offer-type-label">Offer type</InputLabel>
                                <Select
                                    labelId="offer-type-label"
                                    className="boomio_input boomio_select"
                                    aria-label="Default select example"
                                    label="Offer type"
                                    {...register('offer_type')}
                                    value={type}
                                    defaultValue={getValues('title')}
                                    onChange={(e: any) => {
                                        setType(e.target.value)
                                        if (e.target.value)
                                            setValue('offer_type', e.target.value)
                                    }}
                                >
                                    <MenuItem value={'top'}>Top</MenuItem>
                                    <MenuItem value={'common'}>Common</MenuItem>
                                </Select>
                            </FormControl>
                            {errors?.offer_type && <label style={{ color: "red" }}>{errors?.offer_type?.message}</label>}
                        </Grid>
                        <Grid item md={4}>
                            <FormControl fullWidth >
                                <InputLabel>Select status</InputLabel>
                                <Select
                                    className="boomio_input boomio_select"
                                    aria-label="Default select example"
                                    {...register('status')}
                                    value={status}
                                    defaultValue={getValues('status')}
                                    label="Select status"
                                    onChange={(e: any) => {
                                        setStatus(e.target.value)
                                        if (e.target.value)
                                            setValue('status', e.target.value)
                                    }}
                                >
                                    <MenuItem value={'active'}>Active</MenuItem>
                                    <MenuItem value={'inactive'}>InActive</MenuItem>
                                </Select>
                            </FormControl>
                            {errors?.status && <label style={{ color: "red" }}>{errors?.status?.message}</label>}
                        </Grid>
                        <Grid item md={4}>
                            <FormControl fullWidth >
                                <TextField
                                    type="text"
                                    label='Description'
                                    {...register('description')}
                                    placeholder="Description"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => {
                                        setValue('description', e.target.value)
                                    }}
                                    defaultValue={teaserData?.description}
                                    aria-label="default input example"
                                />
                            </FormControl>
                            {errors?.description && <label style={{ color: "red" }}>{errors?.description?.message}</label>}
                        </Grid>
                        <Grid item md={4}>
                            <FormControl fullWidth style={{ position: 'relative' }} >
                                <span className='image_input_text'>Select image</span>
                                <TextField type='file'
                                    onChange={(e) => {
                                        postUploadfile(e)
                                    }}
                                    placeholder='Add description' />
                            </FormControl>
                            <img
                                style={{ marginTop: '10px' }}
                                src={image?.url ? image?.url : image.logoFormData} alt=''
                                width={100}
                                height={100}
                            />
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </>
    )
}

export default AddTeaser   