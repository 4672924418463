import { GridColDef } from '@mui/x-data-grid';
import { apiInstance, authInstance } from '../../../../services/axios';

export const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', flex: 1 },
  { field: 'name', headerName: 'Name', flex: 1 },
  {
    field: 'logo',
    renderCell(params) {
      return <img src={params.row.logo} alt='Logo' height='100%' />
    },
    headerName: 'Logo',
    flex: 1,
  },
  { field: 'userId', headerName: 'User ID', flex: 1, },
  { field: 'email', headerName: 'Email', flex: 1, },
  { field: 'phone', headerName: 'Phone', flex: 1, },
  { field: 'description', headerName: 'description', flex: 1, },
  { field: 'address', headerName: 'Address', flex: 1 },
  {field: 'countryId',headerName: 'Country',flex: 1,},
  {field: 'postalCode',headerName: 'Postal Code',flex: 1,},
  {field: 'created_at',headerName: 'Created AT',flex: 1,},
  {field: 'updated_at',headerName: 'Updated AT',flex: 1,},
  {
    field: 'Login',
    renderCell(params) {
      return <button style={{ backgroundColor: '#1976d2', border: 'none', padding: '10px 16px', color: '#FFFFFF', borderRadius: '3px' }} onClick={async () => {
        const body = {
          b_id: params.row.id
        }
        const response = await apiInstance.post('user-service/admin/generate-token', body).then((res) => {
          const t = res.data.response.token.replace('Bearer ', '')
          const win = window.open(`${process.env.REACT_APP_REDIRECTURL}?token=${t}`)!;
          win.open();
        });
      }}>
        Login</button>
    },
    headerName: 'Action',
    flex: 1,
  },
];
